import React from "react";
import styles from "./contact.module.css";

import { AiOutlineInstagram } from "react-icons/ai";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
const Contact = () => {
  return (
    <div className={styles.contact}>
      <div className={`${styles.contact__section} ${styles.row}`}>
        <div className={styles.contact__primary}>
          <h6 className={styles.h6}>send message</h6>
          <form className={styles.contactForm}>
            <input
              type="text"
              className={styles.full__width}
              placeholder="your name"
            />

            <input
              type="email"
              className={styles.full__width}
              placeholder="your email"
            />
            <input
              type="text"
              className={styles.full__width}
              placeholder="subject"
            />
            <textarea
              type="text"
              className={styles.full__width}
              placeholder="message"
              rows="3"
            />
            <button className={styles.full__width}>submit</button>
          </form>
        </div>
        <div className={styles.contact__secondary}>
          <h6 className={styles.h6}>contact info</h6>
          <div className={styles.cInfo}>
            <h5>where to find us</h5>
            <p>megenagna 24, </p>
            <p>next to kokeb building</p>
          </div>
          <div className={styles.cInfo}>
            <h5>email us at</h5>
            <p>contact@rcndc.com</p>
          </div>
          <div className={styles.cInfo}>
            <h5>call us at</h5>
            <p>092 500 2580</p>
          </div>
          <ui className={styles.social__links}>
            <li>
              <a href="https://www.facebook.com/Alga-106842825174992/">
                <GrFacebookOption />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/alga_room?utm_medium=copy_link">
                <AiOutlineInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/algaroom">
                <GrLinkedinOption />
              </a>
            </li>
          </ui>
        </div>
      </div>
    </div>
  );
};

export default Contact;
