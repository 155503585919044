import Box from "@mui/material/Box";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";

function App() {
  return (
    <Box >
      <Home />
      <Contact />
    </Box>
  )
}

export default App;
