import React from "react";
import styles from "./home.module.css";
const Home = () => {
  return (
    <div className={styles.home}>
      <div className={styles.shadow} />
      <div className={styles.home__content}>
        <div className={`${styles.home__content__main} ${styles.row}`}>
          <h3>Welcome to alga</h3>
          <h1>
            Alga is a leading provider of hotel accommodation in Ethiopia
            offering booking services through its own network of the localized
            mobile app.
          </h1>
          <div className={styles.home__content__buttons}>
            <a className={styles.btn}>
              <div class={styles.icons8__app__store}></div>
              <div className={styles.link}>
                {/* <p className={styles.getIt}>download from </p> */}
                <p className={styles.getIt}>coming soon </p>
                <p className={styles.getItLink}>App store</p>
              </div>
            </a>
            <a
              className={styles.btn}
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.rcndc.my_alga_app"
            >
              <img src="https://img.icons8.com/fluency/50/000000/google-play.png" />
              <div className={styles.link}>
                <p className={styles.getIt}>get it on</p>
                <p className={styles.getItLink}>Google play</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
